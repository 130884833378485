/*
 *   Tabs Component
 */

var $ = require("jquery");

var Tab = Backbone.Model.extend({
  defaults: {
    active: false,
    async: false,
    loaded: false, // loadComponents() flag.
    $label: "", // $TabLabel
    $tab: "", // $TabContent
    id: "" // Tab Id
  },
  initialize: function() {
    // If this Tab is an Async Tab
    if (this.get("async")) {
      this.set("loaded", false);
      this.on("change:active", this.asyncCheck);
    }

    // If current tab is async and it's active retreive content
    if (this.get("async") && this.get("active")) {
      this.asyncCheck();
    }

    return this;
  },
  asyncCheck: function() {
    // If an async tab becomes active and its content is not loaded
    if (this.get("active") && this.get("async") && !this.get("loaded")) {
      this.loadContent();
    }

    return this;
  },
  loadContent: function() {
    // @TODO: Make optional type of request and post data
    $.ajax({
      type: "GET",
      dataType: "html",
      url: this.get("async"),
      success: function(response) {
        this.set("content", response);
        this.set("loaded", true);
      }.bind(this),
      error: function() {
        console.warn(arguments);
      }
    });

    return this;
  },
  reloadContent: function() {
    this.set("loaded", false, { silent: true });
    this.loadContent();
    return this;
  }
});

var Tabs = Backbone.Collection.extend({
  model: Tab
});

var TabsView = Backbone.View.extend({
  events: {
    "click [data-tabs-label]": "tabClicked",
    "keyup [data-tabs-label]": "keyPressedEvent"
  },

  initialize: function(e) {
    this.tabs = new Tabs();
    //if we don't want to prevent further propagation of current event and wants to triggers the ancestors of the target element
    this.allowBubbling = this.$el.data("tabsAllowEventBubbling") || false;

    // Get current options and check is options have triggerData
    this.currentOptions =
      e && e.options && e.options.triggerData ? e.options.triggerData : {};

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Get the device pixel ratio
    var ratio = window.devicePixelRatio || 1;

    // Define the users device screen dimensions
    var screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio
    };

    // iPhone X Detection
    if (iOS && screen.width === 1125 && screen.height === 2436) {
      this.$el.children(".tabs-container").addClass("iphoneX");
    }

    this.reflow();

    // get value of tabsLabel and open the tap label
    if (this.currentOptions && this.currentOptions.tabsLabel) {
      this.$("[data-tabs-label=" + this.currentOptions.tabsLabel + "]").trigger(
        "click"
      );
    }
    return this;
  },
  keyPressedEvent: function(e) {
    var eData = $(e.currentTarget)
      .parent()
      .find("button.is-active");

    if (e.keyCode === 37 || e.keyCode === 38) {
      // left / up arrow
      eData.prev("button").trigger("click");
    }
    if (e.keyCode === 39 || e.keyCode === 40) {
      // right arrow / down arrow
      eData.next("button").trigger("click");
    }
  },

  renderContent: function(tab) {
    let currentTab = tab.get("$tab");
    let currentContent = tab.get("content");
    let currentContentTemplate = currentTab.find("[data-tabs-content-visible]");

    if (tab.get("renderWhenVisible") && currentContentTemplate.length) {
      currentContent = currentContentTemplate.html();
      currentContentTemplate.remove();
    }

    currentTab.html(currentContent).removeClass("is-loading");

    currentTab.loadComponents();
    currentTab.loadModules();
    tab.set("loaded", true);
    return this;
  },
  reflow: function() {
    let defaultTab = this.$el.data("tabs-active-tab");
    let renderWhenVisible = this.$el.data("tabs-visible-render");
    let tabs = [];

    let defaults = {
      tabsScrollTop: false
    };
    this.settings = _.extend(defaults, this.$el.data());

    // Scan all declared tabs and store state
    _.each(
      this.$("[data-tabs-content]:first > [data-tabs-tab]"),
      function(el) {
        let currentTab = $(el);
        let tabData = currentTab.data();
        let tab = {
          id: tabData.tabsTab,
          async: tabData.tabsAsync || false,
          active: currentTab.is(".is-active"),
          default: defaultTab === tabData.tabsTab ? true : false,
          renderWhenVisible: renderWhenVisible ? true : false,
          renderOnInit: tabData.tabLoadInit ? true : false,
          $tab: $(el),
          $label: this.$(
            '[data-tabs-labels]:first > [data-tabs-label="' +
              tabData.tabsTab +
              '"]'
          )
        };

        if (tabData["tabLoadInit"]) {
          currentTab.loadComponents();
          currentTab.loadModules();
        }

        // Set default state for async tabs
        if (tabData["tabsAsync"]) {
          $(el).addClass("is-loading");
        }

        tabs.push(tab);
      }.bind(this)
    );

    // Replace contents of collection with indexed data
    this.tabs.reset(tabs);

    // The following bindings are only for Async Tabs
    this.listenTo(this.tabs, "change:loaded", this.renderContent);

    this.id = this.$el.data("tabs-id") || false;

    this.router = this.$el.data("tabs-routed")
      ? Router.registerComponent(this.id, this.routedTab, this)
      : false;

    //  If default active tab, find it and activate it.
    if (
      (!this.router && defaultTab) ||
      (this.router && !this.router.ifComponentPresent(this.id))
    ) {
      this.switchTo(defaultTab);
    }

    if (this.id) {
      $(document).on(
        "click.tabs." + this.id,
        '[data-tabs-label][data-tabs-id="' + this.id + '"]',
        this.tabClicked.bind(this)
      );
    }

    if (this.settings.tabsForm) {
      this.$("[data-tabs-labels] > [data-tabs-label]").each(function() {
        $(this).attr("data-form-section-validate", defaultTab);
      });
    }

    return this;
  },
  tabClicked: function(e) {
    var eData = $(e.currentTarget).data();
    var tab = eData.target || eData.tabsLabel || null;

    if (eData["tabsId"] && this.id !== eData["tabsId"]) {
      return this;
    }

    if (tab) {
      //if we don't want to prevent further propagation of current event
      if (!this.allowBubbling) {
        e.stopPropagation();
      }

      if (eData["tabsReload"] && this.getActiveTab() === tab) {
        var currentTab = this.tabs.findWhere({ id: tab });
        currentTab
          .reloadContent()
          .get("$tab")
          .addClass("is-loading");
      } else {
        this.switchTo(tab);
      }
    }
    this.$el.trigger("switched", { activeTab: this.getActiveTab() });
    return this;
  },

  routedTab: function(routes, tab) {
    this.switchTo(tab);
  },
  switchTo: function(tab) {
    if (!tab) {
      return;
    }

    var currentTab = this.tabs.findWhere({ active: true });
    var nextTab =
      this.tabs.findWhere({ id: tab }) ||
      this.tabs.findWhere({ default: true });

    // Get Requested Tab and Label
    var req$tab = nextTab ? nextTab.get("$tab") : false;
    var req$label = nextTab ? nextTab.get("$label") : false;
    // We return in case we're trying to switch to the current tab
    if (currentTab && nextTab && currentTab.get("id") === tab) {
      return this;
    }

    if (req$tab.length && req$label) {
      let areaSelected = {
        "aria-selected": true
      };
      // Open Requested Tabs
      req$label.addClass("is-active");
      req$tab.addClass("is-active");
      req$tab.attr(areaSelected);
      req$label.attr(areaSelected);
      scrollTab = this.settings.tabsScrollTop;
      if (_.isNumber(scrollTab) || (_.isString(scrollTab) && scrollTab)) {
        this.handleScroll(req$tab, scrollTab);
      }
      nextTab.set("active", true);
      // Switch Aria

      if (this.router) {
        this.router.componentSwitch(this.id, nextTab.get("id"));
      }

      if (!nextTab.get("loaded") && !nextTab.get("async")) {
        req$tab.loadModules();
        req$tab.loadComponents();
        nextTab.set("loaded", true);
      }
    }

    if (currentTab) {
      // Get Active Tab and Label
      var active$tab = currentTab.get("$tab");
      var active$label = currentTab.get("$label");
      let areaSelectedfalse = {
        "aria-selected": false
      };
      if (active$tab && active$tab.get("id") !== tab) {
        // Close Currently Open Tab
        active$tab.removeClass("is-active");
        active$label.removeClass("is-active");

        active$tab.attr(areaSelectedfalse);
        active$label.attr(areaSelectedfalse);

        currentTab.set("active", false);
      }
    }

    if (
      currentTab &&
      currentTab.get("renderWhenVisible") &&
      !currentTab.get("renderedWhenVisible")
    ) {
      currentTab.set("renderedWhenVisible", true);
      req$tab.trigger("tab:loaded");
    }

    if (this.settings.tabsForm) {
      this.$("[data-tabs-labels] > [data-tabs-label]").each(function() {
        $(this).attr("data-form-section-validate", nextTab.get("id"));
      });
    }

    return this;
  },
  handleScroll: function(req$tab, scrollTab) {
    if (_.isNumber(scrollTab)) {
      offset = scrollTab;
    } else {
      offset = this.$el.find(scrollTab).position().top;
    }
    req$tab.parents("[data-tabs-content]").animate(
      {
        scrollTop: offset
      },
      1500
    );
  },
  getActiveTab: function() {
    var activeTab = this.tabs.findWhere({ active: true });

    if (activeTab) {
      return activeTab.get("id");
    }
    return null;
  }
});

module.exports = TabsView;
