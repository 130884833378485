// Import Revelex Module and Component initializer
var $ = require("./template.loader");

var appBody = $("body");

// adding product class to header
if (REVELEX && REVELEX.settings && REVELEX.settings.currentFlow) {
  appBody.addClass(REVELEX.settings.currentFlow);
}
// Capturing the meta viewport property
var appViewport = $("[data-viewport]");

var isApple = /(macintosh|ipod|iphone|ipad)/.test(navigator.userAgent);

if (isApple) {
  appBody.addClass("is-apple");
}

// This was introduce to be able to switch states on window resize.
// This function on particular helps on waiting until the last interaction
// to do a single call to its function
var waitForFinalEvent = (function() {
  var timers = {};
  return function(callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "windowResize";
    }
    if (timers[uniqueId]) {
      clearTimeout(timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

// This scenario we are only doing 2 resize events, one to support the
// filters and the other one to modify the viewport properties

$(window).resize(() => {
  waitForFinalEvent(
    () => {
      $(window).trigger("resizeView");
    },
    500,
    "windowResize"
  );

  waitForFinalEvent(
    () => {
      $(window).trigger("viewportDeviceResize");
    },
    500,
    "viewportDevice"
  );

  if (isApple) {
    window.innerHeight < window.outerHeight
      ? appBody.addClass("ios-address-bar-on")
      : appBody.removeClass("ios-address-bar-on");
  }
});

// If there's a resize, especially on devtools for testing purposes
// the viewport property gets changes accordingly

$(window).on("viewportDeviceResize", function() {
  var isMobile = /(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)/.test(
    navigator.userAgent
  );

  // change the viewport value depending on the isMobile switch
  isMobile
    ? appViewport.attr(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover,user-scalable=no"
      )
    : appViewport.attr(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=2.0"
      );
});

$(window).trigger("viewportDeviceResize");

//  |*| Global jQuery plugins must be required here. |*|

// Exports forms into javascript objects
require("../../js/vendor/jquery.serializejson");

// jQuery Time Circles
// https://github.com/wimbarelds/TimeCircles
require("./private-vendors/jquery.timeCircles");

// /*** jQuery Masked Input starts ***/
// https://github.com/RobinHerbots/jquery.inputmask
require("jquery.inputmask");
require("jquery.inputmask/../inputmask.numeric.extensions");

// Utility to check if elment is in browser viewport.
require("./private-vendors/jquery.isInViewport");

// Store our masks Library in our jQuery inputmask plugin
$.fn.inputmask.library = require("./libraries/form-field-masks");
// /*** jQuery Masked Input ends ***/

// Async limiter
// https://github.com/strml/async-limiter
global.Limiter = require("async-limiter");
global.globalQueue = new Limiter({ concurrency: 1 });
global.globalQueueResults = [];

// /*** Parsley declaration starts ***/
// http://parsleyjs.org/doc/index.html
var Parsley = require("parsleyjs");
var ValidatorsLibrary = require("./libraries/form-field-validators");

// Register Custom Validators
_.each(
  ValidatorsLibrary.standard,
  function(validator, name) {
    Parsley.addValidator(name, validator);
  }.bind(this)
);

_.each(
  ValidatorsLibrary.async,
  function(validator, name) {
    Parsley.addAsyncValidator(name, validator);
  }.bind(this)
);

// Uncomment languages that we currently support
require("parsleyjs/dist/i18n/de");
require("parsleyjs/dist/i18n/es");
require("parsleyjs/dist/i18n/pt-br");
require("parsleyjs/dist/i18n/pt-pt");
// require('parsleyjs/dist/i18n/ja');
require("parsleyjs/dist/i18n/zh_cn.extra");
require("parsleyjs/dist/i18n/zh_cn");
// require('parsleyjs/dist/i18n/zh_tw');
// require('parsleyjs/dist/i18n/fr.extra');
require("parsleyjs/dist/i18n/fr");
// require('parsleyjs/dist/i18n/en.extra');

// DO NOT ADD LANGUAGES AFTER THIS REQUIRE. NEW LANGUAGE REQUIRES MUST BE MADE ABOVE THIS
require("parsleyjs/dist/i18n/en");

// /*** Parsley declaration ends ***/

require("html5shiv/dist/html5shiv");

/**
 * Simple global listener to toggle class name(s) on specified element(s)
 *
 * Example:
 * <span data-class-toggle="[data-foo]" data-class-toggle-class="text-right">toggle class</span>
 * <p data-foo>Element to toggle</p>
 */
$(document).on("click", "[data-class-toggle]", function(event) {
  $($(event.currentTarget).data("class-toggle")).toggleClass(
    $(event.currentTarget).data("class-toggle-class")
  );
});

if (REVELEX.cookie) {
  // Checking if REVELEX.cookie object exists,
  // if not delete loginName from cookie request
  const cookieSettings = REVELEX.cookie || {
    keys: {
      loginName: ""
    },
    domain: window.location.host
  };

  // Getting ready to create or delete via expiration of cookie
  cookieSettings.expiration = "-1";

  if (cookieSettings.keys) {
    cookieSettings.expiration = new Date();
    cookieSettings.expiration.setTime(
      cookieSettings.expiration.getTime() + 12 * 60 * 60 * 1000
    );
  }

  for (var key in cookieSettings.keys) {
    let loginCookie = key + "=" + cookieSettings.keys[key];

    loginCookie += "; expires=" + cookieSettings.expiration;
    if (cookieSettings.domain) {
      loginCookie += "; domain=" + cookieSettings.domain;
    }
    loginCookie += "; path=/";
    loginCookie += "; SameSite=None; Secure";

    document.cookie = loginCookie;
  }
}

$("[data-self-destruct]").each(function(index, element) {
  var timeout = $(element).data("selfDestruct") || 30000;

  setTimeout(function() {
    $(element).remove();
  }, timeout);
});

$(document).on("click", "[data-self-destruct-trigger]", function() {
  $(this)
    .parents("[data-self-destruct]")
    .remove();
});

// global copy functionality
$(document).on("click", "[data-copy-item]", function() {
  let copyInput = $(this).find("[data-copy-input]");
  $(this).removeClass("fade-in-message");

  if (!copyInput.length) {
    copyInput = document.createElement("input");
    copyInput.setAttribute("type", "text");
    copyInput.setAttribute("class", "is-hidden");
    copyInput.setAttribute("data-copy-input", "");
    copyInput.setAttribute("value", this.innerHTML);

    this.append(copyInput);
  }

  copyInput = copyInput[0] ? copyInput[0] : copyInput;

  copyInput.select();
  copyInput.setSelectionRange(0, 99999);

  navigator.clipboard.writeText(copyInput.value).then(x => {
    $(this).addClass("fade-in-message");
  });
});

module.exports = $;
