var $ = require("jquery");

var Portlet2 = Backbone.View.extend({
  events: {
    "click [data-portlet-modal-trigger]": "renderEdit",
    "click [data-portlet-send]": "sendRequest",
    "click [data-portlet-toggle]": "sendToggle"
  },

  initialize: function() {
    this.settings = {
      id: ""
    };

    this.csrf = null;

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.modal = this.$("[data-portlet-modal]");

    this.initTemplates();

    this.$el.loadComponents();
    this.$el.loadModules();

    this.$('[data-component="content-loader"]').on(
      "content-loaded",
      this.initTemplates.bind(this)
    );
  },

  initTemplates: function() {
    this.templates = {
      messages: Handlebars.compile(
        this.$(
          '[data-portlet-messages-template="' + this.settings.id + '"]'
        ).html() || ""
      ),
      modal: Handlebars.compile(
        this.$(
          '[data-portlet-modal-template="' + this.settings.id + '"]'
        ).html() || ""
      ),
      modalSubtemplate:
        this.$(
          '[data-portlet-modal-subtemplate="' + this.settings.id + '"]'
        ).html() || ""
    };
  },

  renderEdit: function(event) {
    if (
      this.settings.id !== $(event.currentTarget).data("portlet-modal-trigger")
    ) {
      return;
    }

    var self = this;

    this.Dialog = this.modal.component();

    this.dialogContent = this.Dialog.currentModal
      ? this.Dialog.currentModal.find("[data-dialog-body]")
      : this.modal;

    var content = this.templates.modal({
      isEdit: true,
      data: $(event.currentTarget).data("portletData"),
      response: this.csrf
    });

    this.dialogContent.html(content);

    if (!this.Dialog.currentModal) {
      this.Dialog.reflow().open();
    }

    this.Dialog.currentModal
      .find("[data-portlet-modal-subtemplate-body-container]")
      .html(this.templates.modalSubtemplate);
    this.Dialog.reflow();

    this.Dialog.currentModal.on(
      "submit",
      '[data-portlet-form="' + this.settings.id + '"]',
      function(event) {
        event.preventDefault();

        var formData = $(this).serializeJSON();

        $.ajax({
          type: "post",
          dataType: "json",
          cache: false,
          url: $(this).attr("action"),
          data: $(this).serialize(),

          beforeSend: function() {
            self.block(self.Dialog.currentModal.find("[data-dialog-body]"));
          },

          success: function(response) {
            if (response.success) {
              self.Dialog.close();

              if (
                self.$('[data-portlet-reload="' + self.settings.id + '"]')
                  .length
              ) {
                self
                  .$('[data-portlet-reload="' + self.settings.id + '"]')
                  .click();
              } else {
                self.csrf = { csrf: response.csrf };
              }

              self.block(self.Dialog.currentModal.find("[data-dialog-body]"), {
                title: response.messages.general.join("<br>")
              });

              setTimeout(
                self.unblock(
                  self.Dialog.currentModal.find("[data-dialog-body]")
                ),
                500
              );
            } else {
              self.rerenderModal(formData, response);
            }
            return true;
          },

          error: function(response) {
            self.rerenderModal(formData, response);
            return false;
          }
        });
      }
    );
  },

  rerenderModal: function(formData, response) {
    this.Dialog.currentModal.find("[data-dialog-body]").html(
      this.templates.modal({
        data: formData,
        response: response
      })
    );

    this.Dialog.reflow();

    this.unblock(this.Dialog.currentModal.find("[data-dialog-body]"));

    $(this.Dialog.currentModal.find("[data-dialog-body]")).loadComponents();
    $(this.Dialog.currentModal.find("[data-dialog-body]")).loadModules();
  },

  sendRequest: function(event) {
    if (this.settings.id !== $(event.currentTarget).data("portlet-send")) {
      return;
    }

    var self = this;

    $.ajax({
      type: "get",
      dataType: "json",
      cache: false,
      url: $(event.currentTarget).data("portlet-send-url"),

      beforeSend: function() {
        self.block(self.$el);
      },

      success: function(response) {
        if (response.success) {
          self.$('[data-portlet-reload="' + self.settings.id + '"]').click();
        } else {
          self
            .$('[data-portlet-messages="' + self.settings.id + '"]')
            .html(self.templates.messages({ response: response }));
        }

        self.unblock(self.$el);

        return true;
      },

      error: function(response) {
        self
          .$('[data-portlet-messages="' + self.settings.id + '"]')
          .html(self.templates.messages({ response: response }));

        self.unblock(self.Dialog.currentModal.find("[data-dialog-body]"));
        return false;
      }
    });
  },

  sendToggle: function(event) {
    if (this.settings.id !== $(event.currentTarget).data("portlet-toggle")) {
      return;
    }

    var self = this;

    var target = $(event.currentTarget);

    $.ajax({
      type: "get",
      dataType: "json",
      cache: false,
      url: $(event.currentTarget).data("portlet-toggle-url"),

      beforeSend: function() {
        self.block(self.$el);
      },

      success: function(response) {
        if (response.success) {
          target.removeClass("has-error");
        } else {
          target.addClass("has-error");

          if (target.is(":checked")) {
            target
              .prop("checked", false)
              .attr("checked", false)
              .blur();
          } else {
            target
              .prop("checked", true)
              .attr("checked", true)
              .blur();
          }
        }

        self.unblock(self.$el);

        return true;
      },

      error: function(response) {
        self
          .$('[data-portlet-messages="' + self.settings.id + '"]')
          .html(self.templates.messages({ response: response }));

        self.unblock(self.Dialog.currentModal.find("[data-dialog-body]"));
        return false;
      }
    });
  },

  block: function(el) {
    $(el).addClass("is-loading");
  },

  unblock: function(el) {
    $(el).removeClass("is-loading");
  }
});

module.exports = Portlet2;
